<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Список категорий</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <CategoriesTable />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import CategoriesTable from "@/components/Tables/CategoriesTable";

import { mapActions } from "vuex";

export default {
  components: {
    CategoriesTable,
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getCategories();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("categories", ["getCategories"]),
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  max-width: 100%;
}
</style>